import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import BandElement from "../components/BandElement";
import FullWidthImage from "../components/FullWidthImage";

// eslint-disable-next-line
export const BandPageTemplate = ({
  title,
  image,
  band,
}) => {
  const imageHero = getImage(image) || image;

  return (
    <div className="content has-background-black">
      <FullWidthImage img={imageHero} title={title} />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <BandElement gridItems={band} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

BandPageTemplate.propTypes = {
  title: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  band: PropTypes.array,
};

const BandPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <BandPageTemplate
        title={frontmatter.title}
        image={frontmatter.image}
        band={frontmatter.band}
      />
    </Layout>
  );
};

BandPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default BandPage;

export const bandPageQuery = graphql`
  query BandPageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        band {
          image {
            childImageSharp {
              gatsbyImageData(width: 2048, quality: 100, layout: CONSTRAINED)
            }
          }
          alt
          title
          role
          bio
          artists
        }
      }
    }
  }
`;
