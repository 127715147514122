import * as React from "react";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

const BandGrid = ({ gridItems }) => (
  <div className="columns is-multiline">
    {gridItems.map((item, key) => (
      <div key={key} className="column is-12">
        <section className="section columns">
          <div className="column is-6 has-text-centered">
            <div style={{display: "inline-block"}}>
              <PreviewCompatibleImage imageInfo={item} />
            </div>
          </div>
          <div className="column is-6">
            <h2 className="has-text-primary">{item.title}</h2>
            <blockquote className="has-background-black has-text-primary bandrole">
              <p>{item.role}</p>
            </blockquote>
            <p className="jparagraph"><span className="bparagraph">Bio:&nbsp;</span>{item.bio}</p>
            <p className="jparagraph"><span className="bparagraph">Favorite Artists/Bands:&nbsp;</span>{item.artists}</p>
          </div>
        </section>
      </div>
    ))}
  </div>
);

BandGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
};

export default BandGrid;
